exports.components = {
  "component---src-app-index-js": () => import("./../../../src/App/index.js" /* webpackChunkName: "component---src-app-index-js" */),
  "component---src-components-custom-page-index-js": () => import("./../../../src/components/CustomPage/index.js" /* webpackChunkName: "component---src-components-custom-page-index-js" */),
  "component---src-discontinued-index-js": () => import("./../../../src/Discontinued/index.js" /* webpackChunkName: "component---src-discontinued-index-js" */),
  "component---src-employers-index-js": () => import("./../../../src/Employers/index.js" /* webpackChunkName: "component---src-employers-index-js" */),
  "component---src-faq-index-js": () => import("./../../../src/FAQ/index.js" /* webpackChunkName: "component---src-faq-index-js" */),
  "component---src-home-index-js": () => import("./../../../src/Home/index.js" /* webpackChunkName: "component---src-home-index-js" */),
  "component---src-press-index-js": () => import("./../../../src/Press/index.js" /* webpackChunkName: "component---src-press-index-js" */),
  "component---src-science-2-index-js": () => import("./../../../src/Science2/index.js" /* webpackChunkName: "component---src-science-2-index-js" */)
}

